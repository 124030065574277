import img1 from '../Components/Images/Hero Section/HERO SECTION IMAGE 1.png';
import img2 from '../Components/Images/Hero Section/HERO SECTION IMAGE 2.png';
export const background = [
    {
        id:1,
        url:img1
    },
    {
        id:2,
        url:img2
    }
]